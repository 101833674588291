import { useWindowWidth } from "@react-hook/window-size";
import Feature from "components/feature/Feature";
import { memo } from "react";
import { Container } from "react-bootstrap";

const Features = () => {
  const onlyWidth = useWindowWidth();

  console.log(onlyWidth);

  return (
    <Container fluid={true}>
      <Feature title="Stand Out Designs" icon="award-winning.png">
        With our library of beautiful templates to choose from you can ensure
        your resume stands out from the crowd.
      </Feature>
      <Feature
        align={onlyWidth >= 576 ? "right" : "left"}
        title="Secure & Affordable"
        icon="secure-cloud.png"
      >
        We are one of the most affordable online resume builders with free and
        paid optional upgrades available all whilst maintaining your data
        securely in the cloud.
      </Feature>
      <Feature title="AI Powered Suggestions" icon="ai.png">
        Let our advanced AI engine do the hard work. You fill out the key points
        and we will generate the summaries for you.
      </Feature>
      <Feature
        align={onlyWidth >= 576 ? "right" : "left"}
        title="Get To The Top"
        icon="ats.png"
      >
        Our technology ensures your resume isn’t skipped by automatic ATS
        parsers because you left out a key detail or used the wrong format.
      </Feature>
    </Container>
  );
};

export default memo(Features);
