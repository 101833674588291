import { ReactNode, memo } from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";

const StyledH1 = styled.h1`
  background: #ff8200;
  color: white;
  align-items: center;
  font-size: 2rem;
  text-align: center;
  padding: 30px 0 35px;
  margin: 0;
`;

const Wrapper = styled.div`
  background-color: #f8f9fb;
  flex: 1;
  display: flex;
  align-items: center;
`;

const StyledContainer = styled(Container)<{ $maxWidth?: number }>`
  background-color: white;
  padding: 10px 20px;
  @media only screen and (min-width: 576px) {
    padding: 30px 40px;
  }
  ${(props) => (props.$maxWidth ? `max-width: ${props.$maxWidth}px` : "")}
`;

interface InformationPageProps {
  title: string;
  children: ReactNode;
  maxWidth?: number;
}

const InformationPage = (props: InformationPageProps) => {
  const { children, title, maxWidth } = props;

  return (
    <>
      <StyledH1>{title}</StyledH1>
      <Wrapper>
        <StyledContainer $maxWidth={maxWidth}>{children}</StyledContainer>
      </Wrapper>
    </>
  );
};

export default memo(InformationPage);
