import { FC, ReactNode, memo } from "react";
import { Button, ButtonProps } from "react-bootstrap";
import styled from "styled-components";

interface PrimaryButtonProps extends ButtonProps {
  variant?: "solid" | "outline";
  children: ReactNode;
  disabled?: boolean;
}

const primaryColor = "#ff8200";

const StyledButton: FC<ButtonProps> = styled(Button)<{ variant: string }>`
  background-color: ${(props) =>
    props.variant === "outline" ? "white" : primaryColor};
  border-color: ${primaryColor};
  color: ${(props) => (props.variant === "solid" ? "white" : primaryColor)};
  font-weight: bold;
  width: 100%;

  &:focus,
  &:active,
  &:disabled,
  &:hover {
    border-color: ${primaryColor} !important;
    background-color: ${primaryColor} !important;
    color: white !important;
  }
`;

const PrimaryButton = (props: PrimaryButtonProps) => {
  const { children, disabled, variant = "solid", type = "submit" } = props;

  return (
    <StyledButton disabled={disabled} type={type} variant={variant}>
      {children}
    </StyledButton>
  );
};

export default memo(PrimaryButton);
