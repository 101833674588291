import { memo } from "react";
import Page from "components/page/Page";
import { Helmet } from "react-helmet";
import StartBuilding from "components/startBuilding/StartBuilding";
import Features from "components/features/Features";

const HomePage = () => {
  return (
    <>
      <Helmet>
        <title>LitResumes - A powerful and free online resume builder</title>‍
        <meta
          name="description"
          content="Our free resume builder makes creating a standout resume quick and easy ensuring you have the best chance of securing your dream job."
        />
      </Helmet>
      <Page>
        <StartBuilding />
        <Features />
      </Page>
    </>
  );
};

export default memo(HomePage);
