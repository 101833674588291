import Page from "components/page/Page";
import { memo } from "react";
import { Helmet } from "react-helmet";
import InformationPage from "components/informationPage/InformationPage";
import { styled } from "styled-components";
import * as Yup from "yup";
import { Alert, Form, Spinner } from "react-bootstrap";
import { useFormik } from "formik";
import emailSlice from "reducers/emailSlice";
import { useAppDispatch, useAppSelector } from "hooks";
import PrimaryButton from "components/primaryButton/PrimaryButton";
import {
  sendContactErrorSelector,
  sendContactSuccessSelector,
  sendingContactSelector,
} from "selectors/emailSelectors";
import { RootState } from "store";

const StyledHeader = styled.div`
  color: #464646;
  font-weight: bold;
  margin-bottom: 30px;
`;

const StyledLabel = styled(Form.Label)`
  color: #464646;
`;

const StyledText = styled(Form.Control)<{ $hasError: boolean | undefined }>`
  border-color: ${(props: any) =>
    props.$hasError ? "red !important" : "#e8e8ef"};

  &:focus {
    border-color: #28c604;
    box-shadow: none;
  }
`;

const ContactPage = () => {
  const dispatch = useAppDispatch();

  const sendingContact = useAppSelector((state: RootState) =>
    sendingContactSelector(state)
  );

  const sendContactError = useAppSelector((state: RootState) =>
    sendContactErrorSelector(state)
  );

  const sendContactSuccess = useAppSelector((state: RootState) =>
    sendContactSuccessSelector(state)
  );
  const schema = Yup.object({
    email: Yup.string()
      .email("A valid email is required")
      .required("Email is a required field"),
    message: Yup.string().required("Message is a required field"),
  });

  const formik = useFormik({
    validationSchema: schema,
    initialValues: {
      email: "",
      message: "",
    },
    onSubmit: (values: any) => {
      dispatch(emailSlice.actions.sendContact(values));
    },
  });

  return (
    <>
      <Helmet>
        <title>LitResumes - Contact</title>
      </Helmet>
      <Page>
        <InformationPage title="Contact" maxWidth={800}>
          <Form onSubmit={formik.handleSubmit} autoComplete="off">
            <StyledHeader>We are here for you! How can we help?</StyledHeader>
            <Form.Group className="mb-3" controlId="email">
              <StyledLabel>Email address</StyledLabel>
              <StyledText
                $hasError={
                  formik.touched.email && formik.errors.email !== undefined
                }
                value={formik.values.email}
                onChange={formik.handleChange}
              />
            </Form.Group>
            <Form.Group className="mb-4" controlId="message">
              <StyledLabel>Message</StyledLabel>
              <StyledText
                $hasError={
                  formik.touched.message && formik.errors.message !== undefined
                }
                className="form-control"
                id="message"
                as="textarea"
                value={formik.values.message}
                onChange={formik.handleChange}
              />
            </Form.Group>
            {sendContactError && (
              <Alert variant="danger">{sendContactError}</Alert>
            )}
            {sendContactSuccess && (
              <Alert variant="success">Thank you for your message</Alert>
            )}
            {!sendContactSuccess && (
              <PrimaryButton disabled={sendingContact}>
                {!sendingContact && <span>Submit</span>}
                {sendingContact && <Spinner size="sm" />}
              </PrimaryButton>
            )}
          </Form>
        </InformationPage>
      </Page>
    </>
  );
};

export default memo(ContactPage);
