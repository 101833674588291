import { memo } from "react";
import { Form, FormControlProps } from "react-bootstrap";
import styled from "styled-components";

interface TextInputProps extends FormControlProps {
  hasError: boolean | undefined;
  placeholder: string | undefined;
}

const StyledText = styled(Form.Control)<{ $hasError: boolean | undefined }>`
  background: #edeef0;
  border-color: ${(props) => (props.$hasError ? "red !important" : "#edeef0")};

  &:focus {
    border-color: #ffcc00;
    box-shadow: none;
  }
`;

const TextInput = (props: TextInputProps) => {
  const { hasError, value, onChange, placeholder } = props;

  return (
    <StyledText
      placeholder={placeholder}
      $hasError={hasError}
      value={value}
      onChange={onChange}
    />
  );
};

export default memo(TextInput);
