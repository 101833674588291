import Page from "components/page/Page";
import { memo } from "react";
import { Helmet } from "react-helmet";
import InformationPage from "components/informationPage/InformationPage";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Header = styled.h1`
  font-weight: bold;
  font-size: 1.4rem;
  padding: 10px 0;
  display: block;
  margin: 0;
`;

const Information = styled.span`
  display: block;
  margin: 20px 0;

  > a {
    color: #ff8200;
    text-decoration: none;
  }

  > a:hover {
    text-decoration: underline;
  }
`;

const PrivacyPolicyPage = () => {
  return (
    <>
      <Helmet>
        <title>LitResumes - Privacy Policy</title>
      </Helmet>
      <Page>
        <InformationPage title="Privacy Policy" maxWidth={800}>
          <Header>Terms and Conditions for LitResumes</Header>
          <Information>
            At LitResumes, we are committed to protecting your privacy and
            safeguarding your personal information. This privacy policy explains
            how we collect, use, and handle any information that you provide
            while using our website. Please read this policy carefully to
            understand our practices regarding your data.
          </Information>

          <Header>1. Information Collection</Header>

          <Information>
            1.1 Resume Generation: LitResumes allows users to generate online
            resumes by providing information about their employment history,
            education, qualifications, and other relevant details. This
            information is entered by the user and is used to create the resume.
          </Information>

          <Information>
            1.2 Chat GPT: We use Chat GPT to generate text suggestions for
            resumes based on the information provided by the user. Chat GPT
            processes this data anonymously, and no personally identifiable
            information is stored or shared during the process.
          </Information>

          <Header>2. Data Storage and Security</Header>

          <Information>
            2.1 Cloud Storage: User data, including the information provided for
            resume generation, is stored securely in the cloud. We take
            reasonable measures to protect the security and integrity of user
            data.
          </Information>

          <Information>
            2.2 Anonymity: We do not store or share any personally identifiable
            information during the resume generation process. User data is
            processed anonymously to ensure privacy and confidentiality.
          </Information>

          <Header>3. Use of Information</Header>

          <Information>
            3.1 Resume Export: LitResumes allows users to export their resumes
            in popular file formats. The information provided by the user is
            used solely for the purpose of generating the resume and exporting
            it in the desired format.
          </Information>

          <Information>
            3.2 Account-Related Emails: The only emails sent to the user by
            LitResumes will be account-related, such as notifications about
            account creation, password reset requests, or important updates
            about the service.
          </Information>

          <Header>4. Third-Party Services</Header>

          <Information>
            LitResumes may use third-party services to facilitate the website's
            functionality, such as cloud storage providers or analytics
            services. These third parties may have access to user data only to
            the extent necessary to perform their services and are obligated to
            maintain the confidentiality and security of the information.
          </Information>

          <Header>5. Compliance with Laws</Header>

          <Information>
            LitResumes may disclose user information if required to do so by law
            or in response to a valid legal request, such as a court order or
            subpoena.
          </Information>

          <Header>6. Changes to This Privacy Policy</Header>

          <Information>
            We reserve the right to update or modify this privacy policy at any
            time, without prior notice. Any changes made will be effective
            immediately upon posting the updated policy on the LitResumes
            website. We encourage you to review this policy periodically to stay
            informed about how we protect your information.
          </Information>

          <Header>7. Contact Us</Header>

          <Information>
            If you have any questions, concerns, or inquiries regarding this
            privacy policy or the way we handle your information, please{" "}
            <Link to={"/contact"}>contact us</Link>
          </Information>

          <Information>
            By using LitResumes, you acknowledge that you have read, understood,
            and agreed to this privacy policy.
          </Information>
        </InformationPage>
      </Page>
    </>
  );
};

export default memo(PrivacyPolicyPage);
