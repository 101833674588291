import { ReactNode, memo } from "react";
import { Container } from "react-bootstrap";
import { styled } from "styled-components";

const Wrapper = styled(Container)`
  display: flex;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 50px;
  flex-direction: column;

  @media only screen and (min-width: 576px) {
    flex-direction: row;
  }
`;

const StyledH1 = styled.h1`
  font-size: 1.8rem;
  margin-bottom: 10px;
  text-align: center;
  margin-top: 20px;

  @media only screen and (min-width: 576px) {
    text-align: left;
    margin-bottom: 30px;
    margin-top: 0;
  }

  @media only screen and (min-width: 768px) {
    font-size: 2.1rem;
  }

  @media only screen and (min-width: 992px) {
    font-size: 2.4rem;
  }
`;

const Info = styled.span`
  font-size: 1rem;
  text-align: center;

  @media only screen and (min-width: 576px) {
    text-align: left;
  }

  @media only screen and (min-width: 768px) {
    font-size: 1.1rem;
  }

  @media only screen and (min-width: 992px) {
    font-size: 1.2rem;
  }
`;

const Icon = styled.img`
  width: 100px;
  height: 100px;

  @media only screen and (min-width: 577px) {
    width: 150px;
    height: 150px;
  }

  @media only screen and (min-width: 992px) {
    width: 200px;
    height: 200px;
  }
`;

const Text = styled.div<{ $align: string }>`
  flex: 1;
  justify-content: center;
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 576px) {
    ${(props) =>
      props.$align === "right" ? "padding-right: 50px;" : "padding-left: 50px;"}
  }
`;

interface FeatureProps {
  title: string;
  icon: string;
  children: ReactNode;
  align?: "left" | "right";
}

const Feature = (props: FeatureProps) => {
  const { title, icon, children, align = "left" } = props;

  const generateText = () => {
    return (
      <Text $align={align}>
        <StyledH1>{title}</StyledH1>
        <Info>{children}</Info>
      </Text>
    );
  };

  const generateContent = () => {
    if (align === "right") {
      return (
        <>
          {generateText()}
          <Icon src={icon} />
        </>
      );
    }

    return (
      <>
        <Icon src={icon} />
        {generateText()}
      </>
    );
  };

  return <Wrapper>{generateContent()}</Wrapper>;
};

export default memo(Feature);
