// @ts-ignore
import { toastr } from "react-redux-toastr";

import { put, takeLatest } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import userSlice, {
  registerUserSuccess,
  registerUserFailure,
} from "reducers/userSlice";
import RegisterUserRequest from "types/registerUserRequest";

function* registerUserSaga(action: PayloadAction<RegisterUserRequest>) {
  try {
    yield axios.post(`/user/register`, action.payload);
    yield put(registerUserSuccess());
  } catch (error: any) {
    toastr.error("Error Registering User", error.message);
    yield put(registerUserFailure(error.message));
  }
}

function* userSaga() {
  yield takeLatest(userSlice.actions.registerUser, registerUserSaga);
}

export default userSaga;
