import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import emailSlice from "reducers/emailSlice";
import userSlice from "reducers/userSlice";
import emailSagas from "sagas/emailSagas";
import userSagas from "sagas/userSagas";
// @ts-ignore
import { reducer as toastrReducer } from "react-redux-toastr";

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: {
    email: emailSlice.reducer,
    user: userSlice.reducer,
    toastr: toastrReducer,
  },
  // @ts-ignore
  middleware: [sagaMiddleware],
});

sagaMiddleware.run(emailSagas);
sagaMiddleware.run(userSagas);

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export default store;
