import PrimaryButton from "components/primaryButton/PrimaryButton";
import { memo } from "react";
import { Container, Form, Nav, Navbar as ReactNavbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import styled from "styled-components";

const StyledNavbar = styled(ReactNavbar)`
  background: white;
  padding: 0;

  @media only screen and (max-width: 992px) {
    .navbar-collapse button {
      margin-bottom: 10px;
    }
  }
`;

const StyledNavbarToggle = styled(ReactNavbar.Toggle)`
  margin-left: auto;
  border: 0 !important;
  box-shadow: none !important;

  > span {
    background-image: url("data:image/svg+xml,<svg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'><path stroke='rgba(255,130,0, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/></svg>");
  }
`;

const Logo = styled.img`
  width: 140px;
  margin: 20px 0;
`;

const Navbar = () => {
  const handleSignIn = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
  };

  return (
    <StyledNavbar expand="lg" data-bs-theme="light">
      <Container fluid={false}>
        <Link to="">
          <Logo alt="logo" src="logo.png" />
        </Link>
        <StyledNavbarToggle aria-controls="responsive-navbar-nav"></StyledNavbarToggle>
        <ReactNavbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto" navbarScroll></Nav>
          <Form className="d-flex">
            <PrimaryButton
              type="button"
              onClick={handleSignIn}
              variant="outline"
            >
              Sign In
            </PrimaryButton>
          </Form>
        </ReactNavbar.Collapse>
      </Container>
    </StyledNavbar>
  );
};

export default memo(Navbar);
