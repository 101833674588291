import { RootState } from "../store";

export const registeringUserSelector = (state: RootState) =>
  state.user.registeringUser;

export const registerUserSuccessSelector = (state: RootState) =>
  state.user.registerUserSuccess;

export const registerUserErrorSelector = (state: RootState) =>
  state.user.registerUserError;
