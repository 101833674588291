import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import RegisterUserRequest from "types/registerUserRequest";

interface UserState {
  registeringUser: boolean;
  registerUserSuccess: boolean;
  registerUserError: string | undefined;
}

const initialState: UserState = {
  registeringUser: false,
  registerUserSuccess: false,
  registerUserError: undefined,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    registerUser: (
      state: UserState,
      action: PayloadAction<RegisterUserRequest>
    ) => {
      state.registerUserSuccess = false;
      state.registeringUser = true;
    },
    registerUserSuccess: (state: UserState) => {
      state.registerUserSuccess = true;
      state.registeringUser = false;
    },
    registerUserFailure: (state: UserState, action: PayloadAction<string>) => {
      state.registerUserSuccess = false;
      state.registeringUser = false;
      state.registerUserError = action.payload;
    },
  },
});

export const { registerUser, registerUserSuccess, registerUserFailure } =
  userSlice.actions;

export default userSlice;
