import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import App from "./App";
import "./index.css";
import HomePage from "./pages/home/HomePage";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-redux-toastr/lib/css/react-redux-toastr.min.css";
// @ts-ignore
import { Provider } from "react-redux";
import store from "./store";
// @ts-ignore
import "react-slidedown/lib/slidedown.css";
// @ts-ignore
import ReduxToastr from "react-redux-toastr";
import ContactPage from "pages/contact/contactPage";
import PrivacyPolicyPage from "pages/privacyPolicy/privacyPolicyPage";
import TermsAndConditionsPage from "pages/termsAndConditions/termsAndConditionsPage";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "",
        element: <HomePage />,
      },
      {
        path: "privacy-policy",
        element: <PrivacyPolicyPage />,
      },
      {
        path: "terms-and-conditions",
        element: <TermsAndConditionsPage />,
      },
      {
        path: "contact",
        element: <ContactPage />,
      },
    ],
  },
]);

root.render(
  // <React.StrictMode>
  <Provider store={store}>
    {/* @ts-ignore */}
    <RouterProvider router={router} />
    <ReduxToastr
      timeOut={5000}
      newestOnTop={false}
      preventDuplicates
      position="bottom-right"
      transitionIn="fadeIn"
      transitionOut="fadeOut"
      progressBar
      closeOnToastrClick
    />
  </Provider>
  // </React.StrictMode>
);

reportWebVitals();
