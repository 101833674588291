import { ReactNode, memo } from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";

const OuterWrapper = styled.div`
  display: flex;
  flex: 1;
`;

const Wrapper = styled(Container)<{ fluid: boolean }>`
  display: flex;
  flex-direction: column;
  ${(props) => (props.fluid ? "padding-left: 0; padding-right: 0;" : "")}
`;

interface PageProps {
  children: ReactNode;
  fullScreen?: boolean;
}

const Page = (props: PageProps) => {
  const { children, fullScreen } = props;

  return (
    <OuterWrapper>
      <Wrapper fluid={fullScreen ?? true}>{children}</Wrapper>
    </OuterWrapper>
  );
};

export default memo(Page);
