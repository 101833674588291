import Page from "components/page/Page";
import { memo } from "react";
import { Helmet } from "react-helmet";
import InformationPage from "components/informationPage/InformationPage";
import { styled } from "styled-components";
import { Link } from "react-router-dom";

const Header = styled.h1`
  font-weight: bold;
  font-size: 1.4rem;
  padding: 10px 0;
  display: block;
  margin: 0;
`;

const Information = styled.span`
  display: block;
  margin: 20px 0;

  > a {
    color: #ff8200;
    text-decoration: none;
  }

  > a:hover {
    text-decoration: underline;
  }
`;

const TermsAndConditionsPage = () => {
  return (
    <>
      <Helmet>
        <title>LitResumes - Terms & Conditions</title>
      </Helmet>
      <Page>
        <InformationPage title="Terms & Conditions" maxWidth={800}>
          <Header>Terms and Conditions for LitResumes</Header>
          <Information>
            Welcome to LitResumes! These terms and conditions outline the rules
            and regulations for using our website. By accessing and using
            LitResumes, you accept and agree to comply with these terms. If you
            do not agree with any part of these terms, please refrain from using
            our website.
          </Information>

          <Header>1. User Registration</Header>

          <Information>
            1.1 Eligibility: LitResumes is available for use by individuals who
            are at least 16 years old. By using our website, you represent and
            warrant that you are at least 16 years of age.
          </Information>

          <Information>
            1.2 Accuracy of Information: You agree to provide accurate, current,
            and complete information during the registration process and to
            update such information to keep it accurate, current, and complete.
          </Information>

          <Information>
            1.3 Account Security: You are responsible for maintaining the
            confidentiality of your account credentials and for all activities
            that occur under your account. LitResumes is not liable for any loss
            or damage arising from your failure to protect your account
            information.
          </Information>

          <Header>2. Resume Generation and Export</Header>

          <Information>
            2.1 Use of Chat GPT: LitResumes uses Chat GPT to generate text
            suggestions based on the information provided by the user for
            creating resumes. The data is processed anonymously, and no
            personally identifiable information is stored or shared.
          </Information>

          <Information>
            2.2 Exported Resume Files: LitResumes allows users to export their
            resumes in popular file formats. It is the user's responsibility to
            verify the accuracy and completeness of the exported resume.
          </Information>

          <Information>
            2.3 Accuracy of Information: LitResumes is not responsible for the
            accuracy or completeness of the information generated by Chat GPT.
            Users should carefully review and edit the generated content to
            ensure its correctness.
          </Information>

          <Header>3. Data Security and Privacy</Header>

          <Information>
            3.1 Anonymous Data Processing: LitResumes processes user data
            anonymously, and no personally identifiable information is stored or
            shared during the resume generation process.
          </Information>

          <Information>
            3.2 Cloud Storage: User data is stored securely in the cloud.
            LitResumes takes reasonable measures to protect the security and
            integrity of user data.
          </Information>

          <Information>
            3.3 Email Communication: LitResumes may send account-related emails
            to users. These emails are essential for providing important
            updates, notifications, and account-related information.
          </Information>

          <Header>4. Prohibited Activities</Header>

          <Information>
            4.1 Bots and Scripts: Users are prohibited from using bots, scripts,
            or any automated means that may interfere with the functionality or
            integrity of LitResumes.
          </Information>

          <Header>5. Termination</Header>

          <Information>
            LitResumes reserves the right to terminate or suspend user accounts
            without prior notice if there is a violation of these terms or if
            the user engages in any activity that may harm, disrupt, or impair
            the functioning of the website.
          </Information>

          <Header>6. Modifications and Updates</Header>

          <Information>
            LitResumes reserves the right to modify, update, or change these
            terms and conditions at any time. Users are encouraged to review the
            terms regularly. Continued use of LitResumes after any modifications
            constitutes acceptance of the updated terms.
          </Information>

          <Header>7. Governing Law and Jurisdiction</Header>

          <Information>
            These terms and conditions shall be governed by and construed in
            accordance with the laws of Western Australia. Any disputes arising
            out of or relating to these terms shall be subject to the exclusive
            jurisdiction of the courts in Western Australia.
          </Information>

          <Header>8. Contact Us</Header>

          <Information>
            If you have any questions, concerns, or inquiries regarding these
            terms and conditions, please <Link to={"/contact"}>contact us</Link>
          </Information>

          <Information>
            By using LitResumes, you acknowledge that you have read, understood,
            and agreed to these terms and conditions.
          </Information>
        </InformationPage>
      </Page>
    </>
  );
};

export default memo(TermsAndConditionsPage);
