import Spacer from "components/spacer/Spacer";
import { memo } from "react";
import { Link as ReactLink } from "react-router-dom";
import styled from "styled-components";

const Wrapper = styled.div`
  background: #1a1a1a;
  color: white;
  display: flex;
  justify-content: center;
`;

const Link = styled(ReactLink)`
  text-align: center;
  padding: 5px 10px;
  font-size: 0.9rem;
  align-items: center;
  display: flex;
  color: white;
  text-decoration: none;
  &:hover {
    text-decoration: underline !important;
  }
`;

const Footer = () => {
  return (
    <Wrapper>
      <Link to={"/terms-and-conditions"}>Terms & Conditions</Link>
      <Spacer color={"white"} />
      <Link to={"/privacy-policy"}>Privacy Policy</Link>
      <Spacer color={"white"} />
      <Link to={"/contact"}>Contact</Link>
    </Wrapper>
  );
};

export default memo(Footer);
