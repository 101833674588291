import PrimaryButton from "components/primaryButton/PrimaryButton";
import TextInput from "components/textInput/TextInput";
import { useFormik } from "formik";
import { useAppDispatch, useAppSelector } from "hooks";
import { memo } from "react";
import { Container, Form, Row, Col, Spinner, Alert } from "react-bootstrap";
import { Link } from "react-router-dom";
import userSlice from "reducers/userSlice";
import {
  registerUserSuccessSelector,
  registeringUserSelector,
} from "selectors/userSelectors";
import { RootState } from "store";
import { styled } from "styled-components";
import * as Yup from "yup";

const Explainer = styled.img`
  display: none;
  @media only screen and (min-width: 992px) {
    display: block;
    width: 400px;
  }
  @media only screen and (min-width: 1200px) {
    width: 500px;
  }
  @media only screen and (min-width: 1400px) {
    width: 700px;
  }
`;

const StyledHeader = styled.h1`
  font-size: 1.8rem;
  margin-bottom: 20px;
`;

const StyledContainer = styled(Container)`
  display: flex;
  align-items: center;
  @media only screen and (min-width: 1200px) {
    align-items: stretch;
  }
`;

const Right = styled.div`
  flex: 1;
  display: flex;
  align-items: stretch;
  flex-direction: column;
`;

const FormWrapper = styled.div`
  margin-top: 50px;
  margin-bottom: 50px;
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;

  > form {
    max-width: 400px;
    text-align: center;
    display: flex;
    flex-direction: column;
    flex: 1;
  }
`;

const Notice = styled.span`
  color: #5b5b5b;
  font-size: 0.8rem;
  margin-top: 20px;

  > a {
    color: #ff8200;
  }
`;

const FormInner = styled.div`
  flex: 1;
`;

const StartBuilding = () => {
  const dispatch = useAppDispatch();

  const registeringUser = useAppSelector((state: RootState) =>
    registeringUserSelector(state)
  );

  const registerUserSuccess = useAppSelector((state: RootState) =>
    registerUserSuccessSelector(state)
  );

  const schema = Yup.object({
    email: Yup.string()
      .email("A valid email is required")
      .required("Email is a required field"),
    firstName: Yup.string().required("First name is a required field"),
    lastName: Yup.string().required("Last name is a required field"),
  });

  const formik = useFormik({
    validationSchema: schema,
    initialValues: {
      email: "",
      firstName: "",
      lastName: "",
    },
    onSubmit: (values: any) => {
      dispatch(userSlice.actions.registerUser(values));
    },
  });

  return (
    <StyledContainer>
      <Explainer src="image4.png" />

      <Right>
        <FormWrapper>
          <Form onSubmit={formik.handleSubmit} autoComplete="off">
            <FormInner>
              <StyledHeader>
                Start building your resume now for free
              </StyledHeader>
              <Row className="mb-4">
                <Col>
                  <Form.Group controlId="email">
                    <TextInput
                      placeholder="Email"
                      hasError={
                        formik.touched.email &&
                        formik.errors.email !== undefined
                      }
                      value={formik.values.email}
                      onChange={formik.handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-4">
                <Col>
                  <Form.Group controlId="firstName">
                    <TextInput
                      placeholder="First Name"
                      hasError={
                        formik.touched.firstName &&
                        formik.errors.firstName !== undefined
                      }
                      value={formik.values.firstName}
                      onChange={formik.handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="lastName">
                    <TextInput
                      placeholder="Last Name"
                      hasError={
                        formik.touched.lastName &&
                        formik.errors.lastName !== undefined
                      }
                      value={formik.values.lastName}
                      onChange={formik.handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>{" "}
              {registerUserSuccess && (
                <Alert variant="success">
                  Thank you for your submission. We will email you shortly with
                  instructions how to finish setting up your free account.
                </Alert>
              )}
              {!registerUserSuccess && (
                <PrimaryButton disabled={registeringUser}>
                  {!registeringUser && <span>Go</span>}
                  {registeringUser && <Spinner size="sm" />}
                </PrimaryButton>
              )}
            </FormInner>
            <Notice>
              By clicking "Go" you agree to our{" "}
              <Link to="terms-and-conditions">Terms & Conditions</Link> and{" "}
              <Link to="privacy-policy">Privacy Policy</Link>. We'll
              occassionally send you account related emails.
            </Notice>
          </Form>
        </FormWrapper>
      </Right>
    </StyledContainer>
  );
};

export default StartBuilding;
